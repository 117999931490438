import { useRef, useEffect, useState } from 'react';
import styles from './ContactUs.module.scss';
import facebook from 'assets/img/icons/facebook.svg';
import linkedin from 'assets/img/icons/linkedin.svg';
import twitter from 'assets/img/icons/twitter.svg';
import instagram from 'assets/img/icons/instagram.svg';
import youtube from 'assets/img/icons/youtube.svg';
import tiktok from 'assets/img/icons/tiktok.svg';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useExternalContext } from 'contexts/ExternalContext';
import { Alert } from '@mui/material';
import { Loader } from 'components/Loader';
import { MAX_FILE_UPLOAD_SIZE } from 'utils/constants';
import { usePortContext } from 'contexts/PortContext';

const ContactUs = () => {
  const formRef = useRef();
  const { t, i18n } = useTranslation();
  useEffect(() => {
    var localStoreVar = localStorage.getItem('locale');
    i18n.changeLanguage(localStoreVar);
  }, []);

  function handleClick() {
    formRef.current.scrollIntoView({ behavior: 'smooth' });
  }

  const { getCaptcha, captchaData, apiLoading, submitContactUsForm } =
    useExternalContext();
  const [success, setSuccess] = useState(null);
  const { ports } = usePortContext();
  useEffect(() => {
    if (!captchaData) {
      getCaptcha();
    }
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    reset,
  } = useForm();

  const onSubmit = async (data) => {
    setSuccess(null);

    const file = data.file[0];
    if (file.type !== 'application/pdf') {
      setError('file', {
        type: 'filetype',
      });
      return;
    }

    if (file.size > MAX_FILE_UPLOAD_SIZE) {
      setError('file', {
        type: 'max',
      });
      return;
    }

    const formData = new FormData();
    formData.append('name', data.name);
    formData.append('emailAddress', data.email);
    formData.append('captcha', data.captcha);
    formData.append('phoneNumber', data.phone);
    formData.append('mobileNumber', data.mobileNumber);
    formData.append('identityNumber', data.identityNumber);
    formData.append('relationToPorts', data.relation);
    formData.append('port', data.port);
    formData.append('purpose', data.purpose);
    formData.append('details', data.details);
    formData.append('file', file);

    const resp = await submitContactUsForm(formData);
    getCaptcha();

    if (resp?.isSuccess === true) {
      reset();
      setSuccess(true);
      formRef.current.scrollIntoView({ behavior: 'smooth' });
      return;
    }

    if (resp?.error === 'Invalid Captcha') {
      setSuccess(null);
      setError('captcha', {
        type: 'value',
        message: resp?.error,
      });
    } else {
      setSuccess(false);
      formRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      <div className={`${styles.bgHeaderBackground}`}></div>
      <div className="container_max">
        <div className="page_wrapper">
          <h1 className="page_title">{t('Contact Us:')}</h1>
        </div>
      </div>
      <div className="overflow-hidden">
        <div className={`${styles.contactContain}`}>
          <div className="container_max">
            <div className="page_wrapper_sub">
              <div className={`${styles.contactWrap}`}>
                <div className={`${styles.contactBox}`}>
                  <h3>{t('Saudi Ports Authority')}</h3>
                  <p>{t('contactDescription')}</p>
                  {/* <p>
                    {t('E-mail')}{' '}
                    <a href="mailto:mawanicare@mawani.gov.sa">
                      {t('mawanicare@mawani.gov.sa')}
                    </a>
                  </p> */}
                  <p>{t('Phone : 966-11-4050005')}</p>
                  <p>{t('Fax: 966-11-4053508')}</p>
                  <p>{t('po-box')}</p>
                  <p>{t('national-address')}</p>
                  <div className={`${styles.contactSocial}`}>
                    <a
                      href="https://www.facebook.com/MawaniKSA"
                      target="_blank"
                      rel="noreferrer">
                      <img src={facebook} alt="facebook" height={'24px'} />
                    </a>
                    <a
                      href="https://www.linkedin.com/company/mawaniksa/"
                      target="_blank"
                      rel="noreferrer">
                      <img src={linkedin} alt="linkedin" height={'23px'} />
                    </a>
                    <a
                      href="https://twitter.com/MawaniKSA"
                      target="_blank"
                      rel="noreferrer">
                      <img
                        src={twitter}
                        className="mt-1"
                        alt="twitter"
                        height={'19px'}
                      />
                    </a>
                    <a
                      href="https://www.instagram.com/MawaniKSA/"
                      target="_blank"
                      rel="noreferrer">
                      <img
                        src={instagram}
                        className="mt-1"
                        alt="instagram"
                        height={'24px'}
                      />
                    </a>
                    <a
                      href="https://www.youtube.com/channel/UCHe5NmEINRO4XUL25z-ch8w"
                      target="_blank"
                      rel="noreferrer">
                      <img
                        src={youtube}
                        className="mt-1"
                        alt="youtube"
                        width={'30px'}
                      />
                    </a>
                    <a
                      href="https://www.tiktok.com/@mawani.ksa"
                      target="_blank"
                      rel="noreferrer">
                      <img
                        src={tiktok}
                        className="mt-1"
                        alt="tiktok"
                        width={'22px'}
                      />
                    </a>
                  </div>
                </div>
                <div className={`${styles.contactBox}`}>
                  <h3>{t('Contact Center')}</h3>
                  <p>{t('Call us on: 199003')}</p>
                  <p>{t('Outside KSA')}</p>
                  <p>
                    {t('E-mail')}{' '}
                    <a href="mailto:mawanicare@mawani.gov.sa">
                      {t('mawanicare@mawani.gov.sa')}
                    </a>
                  </p>
                  <p>
                    {t('For complaints and suggestions:')}
                    {t('Fill in the')} <a onClick={handleClick}>{t('form')}</a>{' '}
                    {t(
                      'available on the website and attach supporting documents to the complaint electronically.',
                    )}
                  </p>
                  <p>
                    {t('Work hours:')}
                    {t('Get in touch with our team all weekdays')}
                  </p>{' '}
                  <p>{t('From 7am to 10pm')}</p>
                </div>
              </div>
              <div className={`${styles.contactMap}`}>
                <div className="greyExtendWrap">
                  <div className="container_max">
                    <div className="page_wrapper_sub">
                      <h2>{t('Location Map')} </h2>
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d828.9883660039585!2d46.6824065!3d24.7766326!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2efd00705a6601%3A0xe8250719706e6a3!2z4oCP2KfZhNmH2YrYptmHINin2YTYudin2YXZhyDZhNmE2YXZiNin2YbZitihINin2YTZhdio2YbZiSDYp9mE2KzYr9mK2K8!5e1!3m2!1sen!2ssa!4v1710758777481!5m2!1sen!2ssa"
                        width="100%"
                        height="450"
                        allowfullscreen=""
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"
                        title="map"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={`${styles.contactForm}`} ref={formRef}>
                <h2>{t('Complaints and Feedback')}</h2>
                <p className="mb-5">
                  {t(
                    'The General Authority for Ports will study and examine the complaint submitted and respond to the response as soon as possible and within a maximum of 5 working days from the date of receipt.',
                  )}
                </p>
                <form onSubmit={handleSubmit(onSubmit)}>
                  {success && (
                    <Alert severity="success" className="mb-4">
                      {t('Message Sent')}
                    </Alert>
                  )}
                  {success === false && (
                    <Alert severity="error" className="mb-4">
                      {t('Something went wrong')}
                    </Alert>
                  )}
                  <div className="form-field">
                    <label htmlFor="name">
                      {t('Name')}
                      <span className="require">*</span>
                    </label>
                    <input
                      id="name"
                      type="text"
                      name="name"
                      {...register('name', {
                        required: true,
                      })}></input>
                    {errors.name && (
                      <span
                        style={{
                          color: 'red',
                          fontSize: '12px',
                        }}>
                        {t('ERROR NAME')}
                      </span>
                    )}
                  </div>
                  <div className="form-field">
                    <label htmlFor="phoneNumber">
                      {t('Phone Number')}
                      <span className="require">*</span>
                    </label>
                    <input
                      id="phoneNumber"
                      type="text"
                      name="phoneNumber"
                      maxLength={12}
                      {...register('phone', {
                        required: true,
                        pattern: /^[0-9]+$/i,
                        maxLength: 12,
                        minLength: 12,
                      })}></input>
                    {errors.phone && (
                      <span
                        style={{
                          color: 'red',
                          fontSize: '12px',
                        }}>
                        {t('ERROR PHONE')}
                      </span>
                    )}
                  </div>
                  <div className="form-field">
                    <label htmlFor="mobileNumber">
                      {t('Mobile Number (966 xx xxxxxxx)')}
                      <span className="require">*</span>
                    </label>
                    <input
                      id="mobileNumber"
                      type="text"
                      name="mobileNumber"
                      maxLength={12}
                      {...register('mobileNumber', {
                        required: true,
                        pattern: /^966[0-9]+$/i,
                        maxLength: 12,
                        minLength: 12,
                      })}></input>
                    {errors.mobileNumber && (
                      <span
                        style={{
                          color: 'red',
                          fontSize: '12px',
                        }}>
                        {t('ERROR MOBILE')}
                      </span>
                    )}
                  </div>
                  <div className="form-field">
                    <label htmlFor="identityNumber">
                      {t('Identity Number')}
                      <span className="require">*</span>
                    </label>
                    <input
                      id="identityNumber"
                      type="text"
                      name="identityNumber"
                      maxLength={10}
                      {...register('identityNumber', {
                        required: true,
                        minLength: 10,
                        maxLength: 10,
                        pattern: /^1[0-9]+$/i,
                      })}></input>
                    {errors.identityNumber && (
                      <span
                        style={{
                          color: 'red',
                          fontSize: '12px',
                        }}>
                        {t('ERROR IDENTITY')}
                      </span>
                    )}
                  </div>
                  <div className="form-field">
                    <label htmlFor="email">
                      {t('Email')}
                      <span className="require">*</span>
                    </label>
                    <input
                      id="email"
                      type="email"
                      name="email"
                      {...register('email', {
                        required: true,
                        pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i,
                      })}></input>
                    {errors.email && (
                      <span
                        style={{
                          color: 'red',
                          fontSize: '12px',
                        }}>
                        {t('ERROR EMAIL')}
                      </span>
                    )}
                  </div>
                  <div className="form-field">
                    <label htmlFor="relation">
                      {t('Relation to Ports')}
                      <span className="require">*</span>
                    </label>
                    <select
                      id="relation"
                      name="relation"
                      {...register('relation', {
                        required: true,
                      })}>
                      <option>{t('Investor')}</option>
                      <option>{t('Importer / Exporter')}</option>
                      <option>{t('Shipping Line / Agent')}</option>
                      <option>{t('Marine Line / Agent')}</option>
                      <option>{t('Customer Broker')}</option>
                      <option>{t('Other')}</option>
                    </select>
                    {errors.relation && (
                      <span
                        style={{
                          color: 'red',
                          fontSize: '12px',
                        }}>
                        {t('ERROR RELATION')}
                      </span>
                    )}
                  </div>
                  <div className="form-field">
                    <label htmlFor="port">
                      {t('Port')}
                      <span className="require">*</span>
                    </label>
                    <select
                      id="port"
                      name="port"
                      className={styles.capitalize}
                      {...register('port', {
                        required: true,
                      })}>
                      <option>{t('Head Quarter')}</option>
                      {ports?.map((item, index) => (
                        <option
                          key={item?.attributes?.Port}
                          value={item?.attributes?.Port}>
                          {item?.attributes?.Port}
                        </option>
                      ))}
                      {/* <option>{t("Dhiba Port")}</option> */}
                    </select>
                    {errors.port && (
                      <span
                        style={{
                          color: 'red',
                          fontSize: '12px',
                        }}>
                        {t('ERROR PORT')}
                      </span>
                    )}
                  </div>
                  <div className="form-field">
                    <label htmlFor="purpose">
                      {t('Purpose')}
                      <span className="require">*</span>
                    </label>
                    <select
                      id="purpose"
                      name="purpose"
                      {...register('purpose', {
                        required: true,
                      })}>
                      <option>{t('Remark On Services')}</option>
                      <option>{t('Enquiry')}</option>
                      <option>{t('Suggestion')}</option>
                      <option>{t('Complaint')}</option>
                      <option>{t('Remark On Website')}</option>
                      <option>{t('Other')}</option>
                    </select>
                    {errors.purpose && (
                      <span
                        style={{
                          color: 'red',
                          fontSize: '12px',
                        }}>
                        {t('ERROR PURPOSE')}
                      </span>
                    )}
                  </div>
                  <div className="form-field">
                    <label htmlFor="details">
                      {t('Details')}
                      <span className="require">*</span>
                    </label>
                    <textarea
                      rows="5"
                      cols="5"
                      {...register('details', {
                        required: true,
                      })}></textarea>
                    {errors.details && (
                      <span
                        style={{
                          color: 'red',
                          fontSize: '12px',
                        }}>
                        {t('ERROR DETAILS')}
                      </span>
                    )}
                  </div>
                  <div className="form-field">
                    <label htmlFor="file">
                      {t('File')}
                      <span className="require">*</span>
                    </label>
                    <input
                      type="file"
                      id="file"
                      name="file"
                      accept=".pdf"
                      {...register('file', {
                        required: true,
                      })}
                    />
                    <span
                      style={{
                        fontSize: '12px',
                        display: 'block',
                        marginTop: '0.25rem',
                      }}>
                      {t('UPLOAD RESUME')}
                    </span>
                    {errors.file && (
                      <span
                        style={{
                          color: 'red',
                          fontSize: '12px',
                        }}>
                        {t('ERROR FILE')}
                      </span>
                    )}
                  </div>
                  <div className="form-field">
                    <label>
                      {t('Captcha')}
                      <span className="require">*</span>
                    </label>
                    <img
                      src={captchaData}
                      className={'pb-2'}
                      alt="captcha"
                      width={'150px'}
                      height={'60px'}
                    />
                    <input
                      type="text"
                      placeholder={t('Enter Captcha')}
                      {...register('captcha', { required: true })}
                    />
                    {errors.captcha && (
                      <span
                        style={{
                          color: 'red',
                          fontSize: '12px',
                        }}>
                        {t('ERROR CAPTCHA')}
                      </span>
                    )}
                  </div>
                  {apiLoading && <Loader />}
                  {!apiLoading && (
                    <button className="button-default" type="submit">
                      {t('Send')}
                    </button>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
