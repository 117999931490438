import { useExternalContext } from 'contexts/ExternalContext';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styles from './Remarks.module.scss';
import { Alert } from '@mui/material';
import { Loader } from 'components/Loader';

export const Remarks = () => {
  const formRef = useRef(null);
  const { t, i18n } = useTranslation();
  useEffect(() => {
    const localStoreVar = localStorage.getItem('locale') || 'ar';
    i18n.changeLanguage(localStoreVar);
  }, []);

  const { getCaptcha, captchaData, apiLoading, submitRemarksForm } =
    useExternalContext();
  const [success, setSuccess] = useState(null);

  useEffect(() => {
    if (!captchaData) {
      getCaptcha();
    }
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    reset,
  } = useForm();

  const onSubmit = async (data) => {
    setSuccess(null);

    console.log(data);
    const formData = new FormData();
    formData.append('name', data.name);
    formData.append('captcha', data.captcha);
    formData.append('phoneNumber', data.mobileNumber);
    formData.append('details', data.details);

    const resp = await submitRemarksForm(formData);
    getCaptcha();

    if (resp?.isSuccess === true) {
      reset();
      setSuccess(true);
      formRef.current?.scrollIntoView({ behavior: 'smooth' });
      return;
    }

    if (resp?.error === 'Invalid Captcha') {
      setSuccess(null);
      setError('captcha', {
        type: 'value',
        message: resp?.error,
      });
    } else {
      setSuccess(false);
      formRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className={`${styles.remarksForms}`} ref={formRef}>
      <p className="mb-5">{t('Ticket Follow Up Description')}</p>
      <p>{t('To access the Service page, click on the following link:')}</p>
      <a
        href="https://mawanicare.mawani.gov.sa/C/#!/init/A49B3F447239A714B1EAE9EE4A1347DC"
        target="_blank"
        rel="noreferrer"
        className="font-normal underline text-same text-[#2D6A80]">
        {t('Ticket Follow Up')}
      </a>
      {/* <h2>{t('Callback')}</h2>
      <p className="mb-5">
        {t(
          'The General Authority for Ports will study and examine the request submitted and respond as soon as possible',
        )}
      </p>
      <form onSubmit={handleSubmit(onSubmit)}>
        {success && (
          <Alert severity="success" className="mb-4">
            {t('Message Sent')}
          </Alert>
        )}
        {success === false && (
          <Alert severity="error" className="mb-4">
            {t('Something went wrong')}
          </Alert>
        )}
        <div className="form-field form-field-2 ">
          <label htmlFor="name">
            {t('Remarks FullName')}
            <span className="require">*</span>
          </label>
          <input
            id="name"
            type="text"
            name="name"
            {...register('name', {
              required: true,
            })}></input>
          {errors.name && (
            <span
              style={{
                color: 'red',
                fontSize: '12px',
              }}>
              {t('ERROR NAME')}
            </span>
          )}
        </div>

        <div className="form-field form-field-2 ">
          <label htmlFor="mobileNumber">
            {t('Remarks Mobile')}
            <span className="require">*</span>
          </label>
          <input
            id="mobileNumber"
            type="text"
            maxLength={12}
            {...register('mobileNumber', {
              required: true,
              pattern: /^966[0-9]+$/i,
              maxLength: 12,
              minLength: 12,
            })}></input>
          {errors.mobileNumber && (
            <span
              style={{
                color: 'red',
                fontSize: '12px',
              }}>
              {t('ERROR MOBILE')}
            </span>
          )}
        </div>
        <div className="form-field form-field-2 ">
          <label htmlFor="details">
            {t('Remarks')}
            <span className="require">*</span>
          </label>
          <textarea
            rows={5}
            cols={5}
            {...register('details', {
              required: true,
            })}></textarea>
          {errors.details && (
            <span
              style={{
                color: 'red',
                fontSize: '12px',
              }}>
              {t('ERROR DETAILS')}
            </span>
          )}
        </div>

        <div className="form-field form-field-2 ">
          <label>
            {t('Captcha')}
            <span className="require">*</span>
          </label>
          <img
            src={captchaData}
            className={'pb-2'}
            alt="captcha"
            width={'150px'}
            height={'60px'}
          />
          <input
            type="text"
            placeholder={t('Enter Captcha')}
            {...register('captcha', { required: true })}
          />
          {errors.captcha && (
            <span
              style={{
                color: 'red',
                fontSize: '12px',
              }}>
              {t('ERROR CAPTCHA')}
            </span>
          )}
        </div>
        {apiLoading && <Loader />}
        {!apiLoading && (
          <button className="button-default" type="submit">
            {t('Send')}
          </button>
        )}
      </form> */}
    </div>
  );
};
