import { FaInfoCircle, FaProjectDiagram } from 'react-icons/fa';
import MawaniLogo from '../../assets/img/mawanilogo1.png';
import aboutIMG from '../../assets/img/aboutIMG.png';
import styles from './AboutUs.module.scss';
import { useAboutContext } from 'contexts/AboutContext';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';

const About = () => {
  const { abouts } = useAboutContext();

  return (
    <div className={styles.aboutContent}>
      {abouts[0] != null && abouts[0].attributes != null && (
        <h3>{abouts[0].attributes.AboutLabel}</h3>
      )}
      <div className="">
        {abouts[0] != null && abouts[0].attributes != null && (
          <div
            dangerouslySetInnerHTML={{
              __html: abouts[0].attributes.AboutContent,
            }}
          />
        )}
        <div className={styles.aboutStrategy}>
          {abouts[0] != null && abouts[0].attributes != null && (
            <h3>{abouts[0].attributes.StrategyLabel}</h3>
          )}

          <div className={styles.about2Col}>
            {abouts[0] != null && abouts[0].attributes != null && (
              <div
                dangerouslySetInnerHTML={{
                  __html: abouts[0].attributes.Strategy,
                }}
              />
            )}
          </div>
          {abouts[0] != null && abouts[0].attributes != null && (
            <h4>{abouts[0].attributes.ValueLable}</h4>
          )}

          <div className={styles.aboutValues}>
            <ul className={styles.aboutStripe}>
              {abouts[0] != null && abouts[0].attributes != null && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: abouts[0].attributes.StrategyValue,
                  }}
                />
              )}
            </ul>
          </div>
        </div>
        <div className="greyExtendWrap">
          <div className="container_max">
            <div className="page_wrapper_sub">
              <div className={styles.about2Col}>
                <div>
                  {abouts[0] != null && abouts[0].attributes != null && (
                    <h4>{abouts[0].attributes.ObjectivesLabel}</h4>
                  )}

                  <ul className={styles.aboutBullet}>
                    {abouts[0] != null && abouts[0].attributes != null && (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: abouts[0].attributes.StrategicObjectives,
                        }}
                      />
                    )}
                  </ul>
                </div>
                <div>
                  {abouts[0] != null && abouts[0].attributes != null && (
                    <h4>{abouts[0].attributes.PillarsLabel}</h4>
                  )}

                  <ul className={styles.aboutBullet}>
                    {abouts[0] != null && abouts[0].attributes != null && (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: abouts[0].attributes.StrategicPillars,
                        }}
                      />
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.aboutPlans}>
          {abouts[0] != null && abouts[0].attributes != null && (
            <h4>{abouts[0].attributes.PlansLabel}</h4>
          )}
          <ul className={styles.aboutBullet}>
            {abouts[0] != null && abouts[0].attributes != null && (
              <div
                dangerouslySetInnerHTML={{
                  __html: abouts[0].attributes.AmbitionsAndPlans,
                }}
              />
            )}
          </ul>
        </div>
        <div className={styles.aboutAmbWrap}>
          <div className="container_max">
            <div className={`${styles.aboutAmbContain} page_wrapper_sub`}>
              <div className="self-start">
                <img src={aboutIMG} alt="aboutImage" />
              </div>
              <div className={styles.aboutAmbContent}>
                {abouts[0] != null && abouts[0].attributes != null && (
                  <h3>{abouts[0].attributes.AmbitionsLabel}</h3>
                )}
                <ul className={styles.aboutStripe}>
                  {abouts[0] != null && abouts[0].attributes != null && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: abouts[0].attributes.Ambitions,
                      }}
                    />
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default About;
