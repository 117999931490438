import styles from './MediaCenter.module.scss';
import { useArticleContext } from 'contexts/ArticleContext';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { BASE_URL } from 'utils/constants';
import { formatDateToDayMonthYearString } from 'utils/format.string';

const News = ({ limit = null }) => {
  const { t, i18n } = useTranslation();
  const { GetLatest, latestArticles } = useArticleContext();
  var localStore = localStorage.getItem('locale');
  if (localStore == null || localStore == undefined) {
    localStore = 'ar';
  }
  useEffect(() => {
    GetLatest(localStore);
  }, [localStore]);
  return (
    <div>
      <div className={`${styles.mediaCenterGrid}`}>
        {latestArticles &&
          latestArticles
            ?.sort(
              (a, b) =>
                new Date(b?.attributes?.PublishDate || -1) -
                new Date(a.attributes?.PublishDate || -1),
            )
            ?.slice(0, limit || latestArticles?.length)
            ?.map(
              (
                {
                  id,
                  attributes: {
                    Title,
                    Summary,
                    PublishDate,
                    Images,
                    Slug,
                    CardImage,
                  },
                },
                i,
              ) => (
                <div className={`${styles.card}`} key={i}>
                  <div className={`${styles.meta}`}>
                    {PublishDate && (
                      <div className={`${styles.date}`}>
                        {formatDateToDayMonthYearString(PublishDate)}
                      </div>
                    )}
                    <h2>{Title}</h2>
                  </div>
                  <p>{Summary}</p>
                  <a
                    rel='canonical'
                    href={'/article?news=' + Slug}
                    className={`${styles.readLink}`}>
                    {t('Read More')}
                  </a>
                  {Images != null && Images.data != null && (
                    <img
                      src={
                        BASE_URL +
                        (CardImage?.data?.attributes?.url
                          ? CardImage.data.attributes.url
                          : Images.data[0].attributes.url)
                      }
                      alt="news card"
                    />
                  )}
                </div>
              ),
            )}
      </div>
    </div>
  );
};
export default News;
